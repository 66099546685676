// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/pagination";
@import "~@ionic/angular/css/ionic-swiper";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

:root {
    --ion-color-gallaghergreen: #006633;
    --ion-color-gallagherlightgreen: #1D5F33;
}

.ion-color-gallaghergreen {
    --ion-color-base: var(--ion-color-gallaghergreen);
}

.ion-color-gallagherlighgreen {
    --ion-color-base: var(--ion-color-gallagherlightgreen);
}

.back-button-mobile {
    position: absolute;
    top: 15px;
    left: 8px;

    ion-icon {
        font-size: 25px;
    }
}

ion-modal.min-width-modal {
    --width: fit-content;
    --min-width: 60%;
    --height: 200px;
    --border-radius: 16px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

ion-modal.min-width-modal-md {
    --width: fit-content;
    --min-width: 60%;
    --height: 400px;
    --border-radius: 16px;
}

ion-modal.min-width-modal-md-wide {
    --width: fit-content;
    --min-width: 90vw;
    --height: 320px;
    --border-radius: 16px;
}

ion-modal.min-width-modal-lg {
    --width: fit-content;
    --min-width: 90%;
    --height: 600px;
    --border-radius: 16px;
}

ion-button {
    --border-radius: 5px;
}

@media print {
    
    ion-backdrop,
    .tabbar,
    ion-footer,
    ion-content::part(background) {
        display: none !important;
    }

    ion-nav {
        contain: none;
        overflow: visible;
    }

    ion-split-pane {
        display: block;
    }

    .scroll-content,
    ion-modal,
    ion-modal .modal-wrapper,
    ion-modal .ion-page,
    ion-modal .ion-page>ion-content,
    .ion-page,
    .ion-page>ion-content,
    ion-split-pane,
    ion-tab,
    ion-tabs,
    ion-router-outlet,
    .app-root,
    ion-content::part(scroll),
    body {
        contain: none;
        position: initial;
        height: auto;
        overflow: visible;
    }

    .fixed-content, .scroll-content {
        margin-top: 0 !important;
    }
}
.mobile-disable {
    .native-input[disabled].sc-ion-input-md {
        opacity: 1 !important;
    }
}

.datatable-header {
    min-width: 100%;
}

.datatable-body {
    min-width: 100%;
}

.grey-background {
    background: #00000060;
}

.red-text-popup {
    color: red;
}

button:disabled {
    opacity: .8;
}

mat-paginator {
    background-color: #191919 !important;
    color: white !important;

    span {
        color: white !important;
    }
}

// ngx-datatable {
//     min-width: 100%;
//     ::ng-deep * {
//         min-width: 100%;
//     }
// }

// :host ::ng-deep ngx-datatable * {
//     min-width: 100%;
// }